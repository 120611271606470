<template>
  <div class="containers">
    <div class="incubationServicePc">
      <el-row style="width:100%">
        <el-col :span="24" class="header-pic">
          <el-image :src="require('@/assets/img/incubationService/incubationServiceImg.png')" fit="cover" style="width: 100%; height: 100%; z-index: 0;"></el-image>
          <!-- <el-image :src="ventureImg" fit="cover" style="width: 100%; height: 100%; z-index: 0;"></el-image> -->
        </el-col>
      </el-row>
      <!-- 独角兽培育服务 -->
      <div class="moduleBox centerAlign">
        <div class="columnBack"></div>
        <div class="tvBox">
          <div class="tvTopInfo">
            <div class="tvTopLeft">
              <div class="tvTopTitleBox">
                <div class="tvTopTitle">
                  <span class="tvTopTitleBack">INTRODUCTION</span>
                  <span>独角兽培育服务介绍</span>
                </div>
              </div>
              <div class="tvIntroduce">
                <label>10余年产业创业服务</label>
                <label>数百家成员企业上市</label>
                <label>10000+投资机构组成创投联盟</label>
                <label>上海、深圳、杭州、苏州10余个城市产业</label>
                <label>数百家细分行业隐形冠军</label>
              </div>
            </div>
            <div class="tvTopRight">
              <div class="tvTopText">
                依托“预见独角兽”丰富的项目资源及投资机构资源，以及培育创新企业、
                独角兽企业的成功经验到全国100个城市落地生根，助力地方城市产业
                创新升级，为政府、投资机构、产业园区、龙头企业开展对接，为政府
                部门高效管理赋能，为园区招商引智赋能，为企业创新转型赋能，助推
                科技产业发展，加速科技成果转化。
              </div>
            </div>
          </div>
          <el-row class="columnDataBox">
            <el-col :span="4" style="height: 100%;">
              <div class="columnDataLi borBom1">
                <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_1.png')" fit="cover"></el-image>
                <div class="tvProgramaNum"><span>40</span><span class="font_3">项</span></div>
                <div class="tvProgramaText">政府项目</div>
              </div>
            </el-col>
            <el-col :span="4" style="height: 100%;">
              <div class="columnDataLi2 borBom2 borRig">
                <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_2.png')" fit="cover"></el-image>
                <div class="tvProgramaNum"><span>7.1</span><span class="font_3">亿</span></div>
                <div class="tvProgramaText">技术交易额</div>
              </div>
            </el-col>
            <el-col :span="4" style="height: 100%;">
              <div class="columnDataLi2 borBom1 borRig">
                <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_3.png')" fit="cover"></el-image>
                <div class="tvProgramaNum"><span>164</span><span class="font_3">项</span></div>
                <div class="tvProgramaText">成果落地</div>
              </div>
            </el-col>
            <el-col :span="4" style="height: 100%;">
              <div class="columnDataLi2 borBom2 borRig">
                <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_4.png')" fit="cover"></el-image>
                <div class="tvProgramaNum"><span>1000</span><span class="font_3">家</span></div>
                <div class="tvProgramaText">高校院所</div>
              </div>
            </el-col>
            <el-col :span="4" style="height: 100%;">
              <div class="columnDataLi2 borBom1 borRig">
                <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_5.png')" fit="cover"></el-image>
                <div class="tvProgramaNum"><span>100</span><span class="font_3">万+</span></div>
                <div class="tvProgramaText">科创资源</div>
              </div>
            </el-col>
            <el-col :span="4" style="height: 100%;">
              <div class="columnDataLi2 borBom2">
                <el-image class="tvProgramaImg" :src="require('@/assets/img/home/cultivate_6.png')" fit="cover"></el-image>
                <div class="tvProgramaNum"><span>1000</span><span class="font_3">家</span></div>
                <div class="tvProgramaText">培育瞪羚 <br/>（独角兽）企业</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 产品和服务 -->
      <div class="moduleBox centerAlign">
        <div class="tvBox">
          <div class="tvTopInfo" style="margin-bottom: 1.5rem;">
            <div class="tvTopLeft">
              <div class="tvTopTitleBox">
                <div class="tvTopTitle">
                  <span class="tvTopTitleBack">PRODUCT</span>
                  <span>产品和服务</span>
                </div>
              </div>
            </div>
          </div>
          <el-row class="productDataBox">
            <el-col :span="6" style="height: 100%;" class="productElcol">
              <div class="productDataLi">
                <div class="productInfo backImg1">
                  <div class="productNum fontT_1">01</div>
                  <div class="productName fontT_1">赛事合作</div>
                  <div class="productText fontT_1">依托“预见独角兽”丰富的项目资源及投资机构资源，对接政府各类赛事活动，通过赛事活动挖掘优秀的创业项目，通过培育、投融资对接等方式助推企业成长。</div>
                </div>
                <el-image class="productImg" :src="require('@/assets/img/incubationService/incubationImg_1.jpg')" fit="cover"></el-image>
              </div>
            </el-col>
            <el-col :span="6" style="height: 100%;" class="productElcol">
              <div class="productDataLi">
                <el-image class="productImg" :src="require('@/assets/img/incubationService/incubationImg_2.png')" fit="cover"></el-image>
                <div class="productInfo">
                  <div class="productNum fontT_2">02</div>
                  <div class="productName fontT_2">区域创新整体解决方案</div>
                  <div class="productText fontT_3">构建区域内企业、园区、产业等创新要素数据平台，应用大数据、人工智能等技术，洞悉区域产业情况，精准配置资源，为地方政府产业培育及升级服务，高效管理赋能，招商引智赋能，为企业创新转型赋能。</div>
                </div>
              </div>
            </el-col>
            <el-col :span="6" style="height: 100%;" class="productElcol">
              <div class="productDataLi">
                <div class="productInfo backImg2">
                  <div class="productNum fontT_1">03</div>
                  <div class="productName fontT_1">科技大市场运营</div>
                  <div class="productText fontT_1">从技术转移规划设计、线上平台建设与运营、线下服务体系建设、供需资源挖掘集聚、要素资源导入与运营、服务机构引入与管理等方面为科技市场运营提供全面的支撑与服务。</div>
                </div>
                <el-image class="productImg" :src="require('@/assets/img/incubationService/incubationImg_3.png')" fit="cover"></el-image>
              </div>
            </el-col>
            <el-col :span="6" style="height: 100%;" class="productElcol">
              <div class="productDataLi">
                <el-image class="productImg" :src="require('@/assets/img/incubationService/incubationImg_4.png')" fit="cover"></el-image>
                <div class="productInfo">
                  <div class="productNum fontT_2">04</div>
                  <div class="productName fontT_2">榜单评选</div>
                  <div class="productText fontT_3">为了寻找和发现全国新经济领域的翘楚者，嫁接政府、银行、投资机构等多方资源，加速企业成长，预见独角兽研究院联合各大企业、当地政府推出《年度独角兽(潜在、种子)、瞪羚(潜在)企业》等多个榜单。</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 产业创新 -->
      <div class="moduleBox centerAlign">
        <div class="tvBox">
          <div class="tvTopInfo" style="margin-bottom: 1.5rem;">
            <div class="tvTopLeft">
              <div class="tvTopTitleBox">
                <div class="tvTopTitle">
                  <span class="tvTopTitleBack">INNOVATION</span>
                  <span>产业创新</span>
                </div>
              </div>
            </div>
          </div>
          <el-row type="flex" :gutter="20" class="innovateDataBox">
            <el-col :span="4.5" style="height: 100%;" class="innovateElcol">
              <div class="innovateDataLi">
                <el-image class="innovateImg" :src="require('@/assets/img/incubationService/innovateImg_1.png')" fit="cover"></el-image>
                <div class="innovateInfo">技术转移服务</div>
              </div>
            </el-col>
            <el-col :span="4.5" style="height: 100%;" class="innovateElcol">
              <div class="innovateDataLi">
                <el-image class="innovateImg" :src="require('@/assets/img/incubationService/innovateImg_2.png')" fit="cover"></el-image>
                <div class="innovateInfo">知识产权服务</div>
              </div>
            </el-col>
            <el-col :span="4.5" style="height: 100%;" class="innovateElcol">
              <div class="innovateDataLi">
                <el-image class="innovateImg" :src="require('@/assets/img/incubationService/innovateImg_3.png')" fit="cover"></el-image>
                <div class="innovateInfo">科技金融服务</div>
              </div>
            </el-col>
            <el-col :span="4.5" style="height: 100%;" class="innovateElcol">
              <div class="innovateDataLi">
                <el-image class="innovateImg" :src="require('@/assets/img/incubationService/innovateImg_4.png')" fit="cover"></el-image>
                <div class="innovateInfo">个性化服务</div>
              </div>
            </el-col>
            <el-col :span="4.5" style="height: 100%;" class="innovateElcol">
              <div class="innovateDataLi">
                <el-image class="innovateImg" :src="require('@/assets/img/incubationService/innovateImg_5.png')" fit="cover"></el-image>
                <div class="innovateInfo">科技人才服务</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div class="incubationServiceH5">
      <div style="height: 500px;">
        <el-image style="width: 100%;height: 100%;" fit="fill" :src="incubationTopImg" ></el-image>
      </div>
      <div class="incubationServiceDataBox">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="serviceDataBox">
              <div class="serviceVal"><span>107</span><span style="font-size: 12px;color: #000;">项</span></div>
              <div class="serviceName">政府项目</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="serviceDataBox">
              <div class="serviceVal"><span>22027</span><span style="font-size: 12px;color: #000;">家</span></div>
              <div class="serviceName">服务企业</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="serviceDataBox">
              <div class="serviceVal"><span>100</span><span style="font-size: 12px;color: #000;">万+</span></div>
              <div class="serviceName">科创资源</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="serviceDataBox">
              <div class="serviceVal"><span>7.1</span><span style="font-size: 12px;color: #000;">亿</span></div>
              <div class="serviceName">技术交易额</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="serviceDataBox">
              <div class="serviceVal"><span>164</span><span style="font-size: 12px;color: #000;">项</span></div>
              <div class="serviceName">成果落地</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="serviceDataBox">
              <div class="serviceVal"><span>1000</span><span style="font-size: 12px;color: #000;">家</span></div>
              <div class="serviceName">高校院所</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="serviceDataBox">
              <div class="serviceVal"><span>814</span><span style="font-size: 12px;color: #000;">场</span></div>
              <div class="serviceName">科创活动</div>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="serviceDataBox">
              <div class="serviceVal"><span>1000</span><span style="font-size: 12px;color: #000;">家</span></div>
              <div class="serviceName">培育瞪羚（独角兽）企业</div>
            </div>
          </el-col>
        </el-row>
        <div class="titleBack1"></div>
        <div class="serviceTextBox">
          <div class="serviceText">
            依托“预见独角兽”丰富的项目资源及投资机构资源，
            以及培育创新企业、独角兽企业的成功经验到全国100个
            城市落地生根，助力地方城市产业创新升级，为政府、
            投资机构、产业园区、龙头企业开展对接，为政府部门
            高效管理赋能，为园区招商引智赋能，为企业创新转型
            赋能，助推科技产业发展，加速科技成果转化。
          </div>
          <div class="sloganBox flex_between">
            <div class="sloganText">10余年产业创业服务</div>
            <div class="sloganText">数百家细分行业隐形冠军</div>
          </div>
          <div class="sloganBox flex_between">
            <div class="sloganText">10000+投资机构组成创投联盟</div>
            <div class="sloganText">数百家成员企业上市</div>
          </div>
          <div class="sloganBox flex_between">
            <div class="sloganText">上海、深圳、杭州、苏州10余个城市产业</div>
          </div>
        </div>
        <div class="titleBack2"></div>
        <div class="productList" v-for="( res ) in productListData" :key="res.id">
          <div class="productListLeft">
            <el-image class="productListImg" fit="fill" :src="res.img"></el-image>
          </div>
          <div class="productListRight">
            <div class="productListName">{{ res.name }}</div>
            <div class="productListText">{{ res.text }}</div>
          </div>
        </div>
        <div class="titleBack3"></div>
        <div class="industryInnovationBox">
          <div style="height: 1px;"></div>
          <div class="industryInnovationInfo flex_between" style="padding: 0 70px;">
            <div class="industryInnovation_1">
              <div class="industryInnovationImgBox">
                <el-image class="industryInnovationImg" fit="fill" :src="require('@/assets/img/incubationService/industryInnovationImg_1.png')"></el-image>
              </div>
              <div class="industryInnovationName">技术转移服务</div>
            </div>
            <div class="industryInnovation_1">
              <div class="industryInnovationImgBox">
                <el-image class="industryInnovationImg" fit="fill" :src="require('@/assets/img/incubationService/industryInnovationImg_2.png')"></el-image>
              </div>
              <div class="industryInnovationName">知识产权服务</div>
            </div>
          </div>
          <div class="industryInnovationInfo flex_between" style="padding: 0 10px;">
            <div class="industryInnovation_1">
              <div class="industryInnovationImgBox">
                <el-image class="industryInnovationImg" fit="fill" :src="require('@/assets/img/incubationService/industryInnovationImg_3.png')"></el-image>
              </div>
              <div class="industryInnovationName">科技金融服务</div>
            </div>
            <div class="industryInnovation_1">
              <div class="industryInnovationImgBox">
                <el-image class="industryInnovationImg" fit="fill" :src="require('@/assets/img/incubationService/industryInnovationImg_4.png')"></el-image>
              </div>
              <div class="industryInnovationName">个性化服务</div>
            </div>
            <div class="industryInnovation_1">
              <div class="industryInnovationImgBox">
                <el-image class="industryInnovationImg" fit="fill" :src="require('@/assets/img/incubationService/industryInnovationImg_5.png')"></el-image>
              </div>
              <div class="industryInnovationName">科技人才服务</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 打开对话框 -->
    <PoupCode
      :img-url="'../../assets/img/qrcode/qrcode' + qrcodeType + '.png'"
      :center-dialog-visible="centerDialogVisible"
      @poupClose="centerDialogVisible = false"
      @poupOpen="centerDialogVisible = true"
    />
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      qrcodeType: '',
      centerDialogVisible: false,
      ventureImg: "http://file.thinkervc.cn/x7hm5cvkh5t3h1p94romzscayijy1hywmc4e45792wknu24c1b7yus1erx0xkdlmzmarskw9fbujeo4y.png",
      incubationTopImg: "http://file.thinkervc.cn/hrvdh8cg1ynoelryhuxjuh99gyiza6crluks9agnzqwe41imusi5hr0o1x6h9chrdypdt2u24gkq0bgc.png",
      productListData: [
        {
          id: "1",
          name: "赛事合作",
          img: "http://file.thinkervc.cn/27sjkulejs8oef5sfiejgtr0pw7qwu7drqhxjb5uw89hj5cvquccr6qhxev2jorkyjhcpj5tuyoqz00h.png",
          text: "依托“预见独角兽”丰富的项目资源及投资机构资源，对接政府各类赛事活动，通过赛事活动挖掘优秀的创业项目，通过培育、投融资对接等方式助推企业成长。"
        },
        {
          id: "2",
          name: "区域创新整体解决方案",
          img: "http://file.thinkervc.cn/26i36aokwib22r7afp8zbemqus70883natgw08ykngqxr8tqfy1188gpkde2mz2a4tcaah8bjj0dflso.png",
          text: "构建区域内企业、园区、产业等创新要素数据平台，应用大数据、人工智能等技术，洞悉区域产业情况，精准配置资源，为地方政府产业培育及升级服务，高效管理赋能，招商引智赋能，为企业创新转型赋能。"
        },
        {
          id: "3",
          name: "科技大市场运营",
          img: "http://file.thinkervc.cn/xoavdg5srksakq8yqpavkijtalsk6qzo4hzqphwdi04jlwna7rcwx2be588gjkn48vbqi3nspvqb15jh.png",
          text: "从技术转移规划设计、线上平台建设与运营、线下服务体系建设、供需资源挖掘集聚、要素资源导入与运营、服务机构引入与管理等方面为科技市场运营提供全面的支撑与服务。"
        },
        {
          id: "4",
          name: "榜单评选",
          img: "http://file.thinkervc.cn/lh3dlpae34jc1j5ab88erimoo42rtdt92gcbr6idpf9l7an6e368jds16lg1yyj3y8g91hvkk62qeof5.png",
          text: "为了寻找和发现全国新经济领域的翘楚者，嫁接政府、银行、投资机构等多方资源，加速企业成长，预见独角兽研究院联合各大企业、当地政府推出《年度独角兽(潜在、种子)、瞪羚(潜在)企业》等多个榜单。"
        }
      ]
    };
  },
  mounted() {
    this.platformTotal1 = 10000;
    this.platformTotal2 = 60;
    this.platformTotal3 = 60;
    this.platformTotal4 = 100;
  },
  methods: {
    poupClose() {
      console.log('centerDialogVisible = false')
      this.centerDialogVisible = false
    },
    poupOpen() {
      console.log('centerDialogVisible = false')
      this.centerDialogVisible = true
    }
  }
};
</script>
<style lang="scss" scoped>
.containers{
  margin-top: 2px;
}
/* 去除默认样式 */
a {
  text-decoration: none;
  color: #333;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  color: #f08519;
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
::v-deep .el-carousel__indicators--horizontal {
  left: auto !important;
  bottom: 1% !important;
  right: 2% !important;
  transform: translateX(0) !important;
}
::v-deep .el-row {
  margin-right: 0;
}
/* 修改弹窗样式 */
::v-deep .el-dialog {
  height: 30rem;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }
}
.verticals{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.transition{transition: .5s;-moz-transition: .5s;-webkit-transition: .5s;-o-transition: .5s;-ms-transition: .5s;}
/* 两端对齐，项目之间的间隔都相等 */
.flex_between {
	display: flex;
	justify-content: space-between;
}
.centerAlign{
	display: flex;
	align-items: center;
}
.line4{
	overflow:hidden;
	text-overflow:ellipsis;
	white-space: normal;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:4;
}
.incubationServicePc{
  min-height: 100%;
  display: block;
}
.incubationServiceH5{
  display: none;
}
.header-pic {
  position: relative;
  width: 100%;
  height: 48rem;
}
.moduleBox{
  width: 100%;
  padding: 5rem 0;
  overflow: hidden;
}
.columnBack{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 34.81rem;
  height: 100%;
  background-image: url("http://file.thinkervc.cn/825dchoro8qogzzl3c3aha274exh19zr9l49kdigaxlw0fvd1c5v627rui4c46xn58lxc3jt5ica6rb1.png");
  background-size: 100% 100%;
}
.tvBox{
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0 15rem;
  overflow: hidden;
}
.tvTopInfo{
  overflow: hidden;
}
.tvTopLeft{
  float: left;
  width: 50%;
  overflow: hidden;
}
.tvTopTitleBox{
  height: 4rem;
}
.tvTopTitleBack{
  position: absolute;
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  opacity: 0.11;
  font-size: 3rem;
  font-family: Arial-Black;
  text-align: left;
  color: #000000;
  text-shadow: 0px 4px 0px 0px rgba(157,65,4,0.22);
}
.tvTopTitle{
  position: relative;
  float: left;
  width: 100%;
  font-size: 2rem;
  font-family: SourceHanSansCN-Bold;
  text-align: left;
  color: #222222;
  font-weight: 700;
  line-height: 4.3rem;
  letter-spacing: 0.04rem;
  cursor: pointer;
}
.columnImg {
  float: left;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.08rem;
  cursor: pointer;
}
.tvIntroduce {
  height: 1.5rem;
  font-size: 1.5rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #8d8d8d;
  line-height: 1.38rem;
  cursor: pointer;
}
.tvIntroduce label {
  float: left;
  height: 2rem;
  background: rgba(0,0,0,0.00);
  border: 0.06rem solid #ff8401;
  border-radius: 1.06rem;
  line-height: 2rem;
  text-align: center;
  padding: 0 20px;
  margin: 0 1rem 1rem 0;
  font-size: 1rem;
  color: #ff7200;
  cursor: pointer;
}
.tvTopRight{
  float: right;
  width: 50%;
  overflow: hidden;
  cursor: pointer;
}
.tvTopText{
  width: 42.5rem;
  font-size: 1rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: justify;
  text-indent: 2em;
  color: #333333;
  line-height: 2rem;
  padding: 3.8rem 0 0 2.5rem;
}
.columnDataBox{
  width: 100% !important;
  height: 22.44rem;
  background: #ffffff;
  margin-top: 5.69rem;
}
.columnDataLi{
  height: 100%;
  background: #f08307;
  color: #ffffff;
  padding: 4.19rem 0 0 2.5rem;
}
.tvProgramaImg{
  width: 4rem;
  height: 4rem;
  cursor: pointer;
}
.tvProgramaNum{
  height: 4.13rem;
  font-size: 3rem;
  font-family: DIN, DIN-Bold;
  font-weight: 700;
  text-align: left;
  line-height: 4.13rem;
  margin-top: 1.75rem;
  cursor: pointer;
}
.font_3{
  font-size: 1.13rem;
  font-weight: 500;
}
.tvProgramaText{
  height: 1.5rem;
  font-size: 1.5rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  line-height: 1.5rem;
  margin-top: 2rem;
  letter-spacing: 2px;
  cursor: pointer;
}
.columnDataLi2{
  height: 100%;
  color: #333333;
  padding: 4.19rem 0 0 2.5rem;
}
.borRig{
  border-right: 1px solid #F0F3F8;
}
.borBom1{
  border-bottom: 0.5rem solid #FFCE7A;
}
.borBom2{
  border-bottom: 0.5rem solid #FFA844;
}
.productDataBox{
  width: 100% !important;
  height: 24.38rem;
  background: #ffffff;
  margin-top: 1rem !important;
}
.productDataLi{
  height: 100%;
}
.productShow{
  display: block;
}
.productShow2{
  display: none;
}
.productInfo{
  width: 100%;
  height: 14.38rem;
  padding: 1rem 2rem;
  background-color: #ffffff;
}
.backImg1{
  background-image: url( "http://file.thinkervc.cn/gvvf1smtt2oztyid7ppx81kw91unch1shye41a1llj7ahtrdcan35pw9ixy3rdtoqqj7shr9e60pqr8a.png" );
  background-size: 100% 100%;
}
.backImg2{
  background-image: url( "http://file.thinkervc.cn/u44k5gc1nzt8wsiaygj0y4jtff5wiobmukev8u7fgldfweum30nn7wewr5pgcomr1r68zjqfdax1sabv.png" );
  background-size: 100% 100%;
}
.productImg{
  width: 100%;
  height: 10rem;
}
.productNum{
  font-size: 1.25rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0.8rem;
}
.productName{
  font-size: 1.25rem;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0.8rem;
}
.productText{
  font-size: 0.9rem;
  font-family: SourceHanSansCN-Normal;
  text-align: left;
  line-height: 1.5rem;
}
.fontT_1{
  color: #ffffff;
}
.fontT_2{
  color: #333333;
}
.fontT_3{
  color: #979190;
}
.innovateDataBox{
  width: 100% !important;
  height: 24.01rem;
  background: #ffffff;
  margin-top: 1rem !important;
}
.innovateDataLi{
  height: 100%;
}
.innovateImg{
  width: 100%;
  height: 16.13rem;
}
.innovateInfo{
  width: 100%;
  height: 7.88rem;
  background-color: #ffffff;
  line-height: 7.88rem;
  text-align: center;
  font-size: 1.75rem;
  font-family: SourceHanSansCN-Regular;
  color: #000000;
  margin-top: -5px;
}
.innovateDataLi:hover .innovateInfo{
  background-color: #F08307;
  color: #ffffff;
}
@media screen and (max-width: 1200px) {
  .incubationServicePc{
    display: none;
  }
  .incubationServiceH5{
    display: block;
    // padding-top: 58px;
    padding-bottom: 50px;
  }
  .incubationServiceDataBox{
    width: 95%;
    margin: -50px auto 0 auto;
    overflow: hidden;
    padding-bottom: 10px;
  }
  .serviceDataBox{
    height: 109px;
    background: #ffffff;
    border: 0.5px solid #ff8401;
    box-shadow: 0px 4.5px 14.5px 0px rgba(255,164,91,0.24);
    border-radius: 7px 0px 0px 0px;
    margin-bottom: 12px;
  }
  .serviceVal{
    height: 78px;
    font-size: 25px;
    font-family: DIN-Bold;
    font-weight: 700;
    text-align: center;
    color: #f89d49;
    line-height: 78px;
  }
  .serviceName{
    height: 31px;
    background: #ffebd5;
    border: 0.5px solid #ff8401;
    border-radius: 7px 0px 0px 0px;
    font-size: 15px;
    font-family: SourceHanSansCN-Regular;
    text-align: center;
    color: #ff8401;
    line-height: 31px;
    letter-spacing: 1.05px;
  }
  .serviceTextBox{
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 6px 0px rgba(255,114,0,0.16);
    overflow: hidden;
    padding: 25px 20px;
  }
  .serviceText{
    font-size: 12px;
    font-family: SourceHanSansCN-Regular;
    text-align: justify;
    text-indent: 2em;
    color: #000000;
    line-height: 24px;
    letter-spacing: 0.9px;
    margin-bottom: 30px;
  }
  .sloganBox{
    width: 100%;
    overflow: hidden;
    margin-bottom: 12px;
  }
  .sloganText{
    height: 25px;
    background: rgba(0,0,0,0.00);
    border: 0.5px solid #ff8401;
    border-radius: 12.5px;
    padding: 0 10px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular;
    text-align: center;
    line-height: 25px;
    color: #ff7200;
  }
  .productList{
    height: 142.5px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 1.5px 19px 0px rgba(31,32,52,0.09);
    padding: 16px 12px 16px 6px;
    margin-top: 15px;
  }
  .productListLeft{
    float: left;
    width: 40px;
    height: 100%;
  }
  .productListImg{
    width: 40px;
    height: 40px;
  }
  .productListRight{
    float: right;
    width: calc(100% - 53px);
    height: 100%;
  }
  .productListName{
    font-size: 18px;
    font-family: SourceHanSansCN-Bold;
    text-align: left;
    color: #ff6600;
    font-weight: 700;
  }
  .productListText{
    font-size: 12px;
    font-family: SourceHanSansCN-Normal;
    text-align: left;
    color: #979190;
    line-height: 18px;
    margin-top: 8px;
  }
  .industryInnovationBox{
    height: 210.5px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 6px 0px rgba(255,114,0,0.16);
    margin-top: 20px;
    padding: 0 16px;
  }
  .industryInnovationInfo{
    height: 50px;
    margin-top: 37px;
  }
  .industryInnovation_1{
    width: 33%;
    height: 100%;
  }
  .industryInnovationImgBox{
    width: 100%;
    height: 25px;
    text-align: center;
    margin-bottom: 10px;
  }
  .industryInnovationImg{
    width: 25px;
    height: 25px;
  }
  .industryInnovationName{
    font-size: 14px;
    font-family: SourceHanSansCN-Regular;
    text-align: center;
    color: #000000;
    letter-spacing: -0.28px;
    white-space: nowrap;
  }
  .titleBack1{
    width: 80%;
    height: 50px;
    margin: 50px auto 15px auto;
    background-image: url( "http://file.thinkervc.cn/5ks74wju3e353iupk9wzosswdu44s9j64ymwkapco5eiks7myy6g8o7xh4o0cmdncecg2rymzl98oayn.png" );
    background-size: 100% 100%;
  }
  .titleBack2{
    width: 60%;
    height: 50px;
    margin: 50px auto 15px auto;
    background-image: url( "http://file.thinkervc.cn/7mhe58oazozhl9jx1ckv8k4p5sjm8umuwmswgygggg7gsfmnl38uceywm69ki3xbjyndzpacalkyfubw.png" );
    background-size: 100% 100%;
  }
  .titleBack3{
    width: 60%;
    height: 50px;
    margin: 50px auto 15px auto;
    background-image: url( "http://file.thinkervc.cn/2aee776xxynsia3ucvoitfp9tinf7fiv7i8hhut7dvwtl857ryb90o4hhzeby20crpi091zvuo8yrto7.png" );
    background-size: 100% 100%;
  }
}
</style>
